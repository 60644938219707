import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LoginGUI.scss';
import { ReactComponent as Logo } from './assets/logoBootUp.svg';

const LoginGUI = ({ onFinish }: { onFinish: () => void }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false); // Track authentication state
  const [bootStarted, setBootStarted] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [usernameOrEmail, setUsername] = useState('');  // User input
  const [password, setPassword] = useState('');  // User input
  const [error, setError] = useState('');        // Error message display

  const bootMessages = [
    "Welcome...",
    "✅ Initializing models...",
    "✅ Loading entity system...",
    "✅ Connecting to external data...",
    "✅ Verifying system integrity...",
    "✅ Boot complete. Entering GalOS..."
  ];

  console.log("LoginGUI.tsx")

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      // If a token exists, skip login and start boot process immediately
      setIsAuthenticated(true);
      setBootStarted(true);
    }
  }, []);

  // Simulate the boot process with timed intervals
  useEffect(() => {
    if (bootStarted) {
      const interval = setInterval(() => {
        if (currentMessage < bootMessages.length - 1) {
          setCurrentMessage(currentMessage + 1);
        } else {
          clearInterval(interval);
          setTimeout(() => onFinish(), 1000); // Delay before entering the desktop
        }
      }, 1500); // 1.5 seconds between each message

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [bootStarted, currentMessage, onFinish]);

  // Start the boot process after authentication
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5001/login', {  // Call the backend, not Auth0 directly
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: usernameOrEmail,
          password: password,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
         // IMPT: Save the token
        localStorage.setItem('authToken', data.access_token);
        setIsAuthenticated(true); // Mark as authenticated
        setTimeout(() => setBootStarted(true), 1000); // Start boot after login
      } else {
        const errorData = await response.json();
        setError(errorData.error_description || 'Invalid login credentials');
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message); // Log the error message
        setError(`An error occurred: ${err.message}`); // Show the error message to the user
      } else {
        console.error("Unexpected error:", err); // Handle other unexpected errors
        setError("An unexpected error occurred.");
      }
    }
  };
  


  return (
    <div className="bootScreen">
      <div className='heroPicture'>
        <div className='bio'>
            <div className='nameBirth'>
                <div className='name'>Anna Fisher</div>
                <div className='birth'>b. 1949</div>
            </div>
                American chemist, emergency physician, and NASA astronaut.
        </div>
      </div>
      <div className='bootArea'>
          <div className="bootLogo">
            <Logo className='symbol'/>
            <h1 className='wordmark'>GalOS</h1>
          </div>
          {!isAuthenticated && (
            <div className='authentication'>
              <form onSubmit={handleLogin}>
                <input
                  type="text"
                  placeholder="Username"
                  value={usernameOrEmail}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {error && <p className="error">{error}</p>}
                <button type="submit">Log In</button>
              </form>
            </div>
          )}
          {bootStarted &&
            (<div className='bootProgress'>
              <div className="bootLoader">
                <div className="progressBar" style={{ width: `${(currentMessage / (bootMessages.length - 1)) * 100}%` }} />
              </div>
              <div className="bootMessages">
                {bootMessages.slice(0, currentMessage + 1).map((message, index) => (
                  <p key={index}>{message}</p>
                ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default LoginGUI;
