// AppRouter.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChatApp from './apps/ChatApp';
import LoginApp from './LoginApp'; // Standalone Chat Login
import NotFound from './NotFound';
import ProtectedRoute from './components/ProtectedRoute';

const AppRouter = () => {
  return (
    <Routes>
      {/* Standalone components */}
      <Route
        path="/app/chat"
        element={
          <ProtectedRoute>
            <ChatApp onClose={() => {}} title="Chat" />
          </ProtectedRoute>
        }
      />
      <Route path="/app/login" element={<LoginApp />} />
      
      {/* Catch-all for standalone apps */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
