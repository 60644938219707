import React, { useState } from 'react';
import '../themes/default_main.scss';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import CustomMessageInput from '../CustomMessageInput';
import SourceEntities from '../SourceEntities';
import RespondingDots from '../RespondingDots';
import { EntityType, EmailType, AppleNoteType } from '../types';
import AppWrapper from '../AppWrapper';

interface MessageType {
  message: string;
  sentTime: string;
  sender: string;
  sourceEntities?: EntityType[];
}

const ChatApp = ({ onClose, title }: { onClose: () => void, title: string }) => {
  const [messages, setMessages] = useState<MessageType[]>([
    { message: "Hello! How can I assist you today?", sentTime: "just now", sender: "AI", sourceEntities: [] }
  ]);

  const [sourceEntities, setSourceEntities] = useState<EntityType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'));

  const handleSend = async (message: string): Promise<void> => {
    setMessages((prevMessages: Array<MessageType>) => [
      ...prevMessages,
      { message: message, sentTime: "just now", sender: "User" },
    ]);

    setLoading(true); // waiting for backend to respond

    try {
      const response = await fetch("https://humble-new-mammoth.ngrok-free.app/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ message }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);

      // Map both Email and AppleNote entities to match the structure
      const entities: EntityType[] = (data.entities_used || []).map((entity: any) => {
        if (entity.entityType === 'Email') {
          return {
            entityType: 'Email',
            gmail_id: entity.gmail_id,
            subject: entity.subject,
            from: entity.from,
            to: entity.to,
            date: entity.date,
            cleaned_plain_text: entity.cleaned_plain_text,
          } as EmailType;
        } else if (entity.entityType === 'AppleNote') {
          return {
            entityType: 'AppleNote',
            title: entity.title,
            plaintext: entity.plaintext,
            folder: entity.folder,
            created_at: entity.created_at,
            modified_at: entity.modified_at,
          } as AppleNoteType;
        }
        return null;
      }).filter((entity: EntityType | null): entity is EntityType => entity !== null);  // Filter out null values

      console.log(entities);

      // update states with response data
      setMessages((prevMessages: Array<MessageType>) => [
        ...prevMessages,
        { message: data.answer, sentTime: "just now", sender: "AI", sourceEntities: entities },
      ]);
      setSourceEntities(entities);
    } catch (error) {
      console.error("Error during fetch:", error);
      setMessages((prevMessages: Array<MessageType>) => [
        ...prevMessages,
        { message: "Error: Unable to reach AI", sentTime: "just now", sender: "AI", sourceEntities: [] },
      ]);
    } finally {
      setLoading(false); // Hide animation when the response arrives
    }
  };

  return (
    // Conditionally wrap in AppWrapper based on GUI vs standalone
    onClose ? (
      <AppWrapper title={title} onClose={onClose}>
        <ChatAppContent
          messages={messages}
          handleSend={handleSend}
          loading={loading}
          sourceEntities={sourceEntities}
        />
      </AppWrapper>
    ) : (
      <ChatAppContent
        messages={messages}
        handleSend={handleSend}
        loading={loading}
        sourceEntities={sourceEntities}
      />
    )
  );
};

// Reusable ChatApp content
const ChatAppContent = ({
  messages,
  handleSend,
  loading,
  sourceEntities,
}: {
  messages: MessageType[];
  handleSend: (message: string) => void;
  loading: boolean;
  sourceEntities: EntityType[];
}) => (
  <div className="appMain">
    <div className='nav'>
      <div className='aiName'>
        Gal 001
      </div>
    </div>
    <MainContainer>
      <ChatContainer>
        <MessageList>
          {messages.map((msg, index) => (
            <Message
              key={index}
              model={{
                direction: msg.sender === "User" ? "outgoing" : "incoming",
                position: "normal",
                type: "custom",
              }}
            >
              <Message.CustomContent>
                <>
                  {msg.message}
                  {msg.sender !== "User" && msg.sourceEntities && msg.sourceEntities.length > 0 && (
                    <div>
                      <SourceEntities sourceEntities={msg.sourceEntities || []} />
                    </div>
                  )}
                </>
              </Message.CustomContent>
            </Message>
          ))}
          {loading && <RespondingDots />}
        </MessageList>
        <CustomMessageInput as={MessageInput} sendMessage={handleSend} />
      </ChatContainer>
    </MainContainer>
  </div>
);

export default ChatApp;
