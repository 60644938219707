// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import './index.css';
import System from './System'; // Full GUI system
import AppRouter from './AppRouter'; // Standalone apps (e.g., Chat, Login, etc.)
import reportWebVitals from './reportWebVitals';

// This component conditionally renders either the System or the AppRouter based on the URL path
const AppEntry = () => {
  const location = useLocation();

  // If the URL starts with /app, render standalone apps; otherwise, render the full System GUI
  if (location.pathname.startsWith('/app')) {
    return <AppRouter />;
  }

  return <System />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppEntry />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
