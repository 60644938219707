import React, { useState } from 'react';
import './AppWrapper.scss';

interface AppWrapperProps {
  children: React.ReactNode;
  title: string;
  onClose: () => void;  // Handles closing the app
}

const isStandalone = window.location.pathname.startsWith('/app');



const AppWrapper = ({ children, onClose, title }: AppWrapperProps) => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  
  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove token from local storage
    setAuthToken(null); // Clear auth token from state
    window.location.reload(); // Reload the page (you can also redirect to a login page)
  };

  return (
    <div className={isStandalone ? 'standalone appWrapper' : 'gui appWrapper'}>
      <div className="appHeader">
        <span>{title}</span>
        <div className='appControls'>
          {isStandalone &&
            <div className='standaloneAppControls'>
              <div className='username'>username</div>
              <button onClick={handleLogout} className="logoutButton">Log Out</button>
            </div>
          }
          {!isStandalone &&
            <button className="closeButton" onClick={onClose}>×</button>
          }
        </div>
      </div>
      <div className="appContent">
        {children} {/* The specific app content goes here */}
      </div>
    </div>
  );
}

export default AppWrapper;
