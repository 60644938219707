import { EmailType, AppleNoteType } from './types';


type EntityType = EmailType | AppleNoteType;

interface SourceEntitiesProps {
  sourceEntities: EntityType[];
}

const SourceEntities = ({ sourceEntities }: SourceEntitiesProps) => {
  return (
    <>  
      <div className='sourceEntities'>
        {sourceEntities.map((entity) => (
          <div key={entity._id} className="sourceEntity">
            {entity.entityType === 'Email' ? (
              // Rendering for Email entities
              <div className="sourceEmail">
                <span><strong>📧 {entity.subject}</strong></span>
                <span>
                  <span>{entity.from} • </span>
                  <span className='date'>{entity.date || "unknown"}</span>
                </span>
              </div>
            ) : entity.entityType === 'AppleNote' ? (
              // Rendering for AppleNote entities
              <div className="sourceAppleNote">
                <span><strong>📝 {entity.title}</strong></span>
                <span>
                  <span>{entity.folder || "unknown folder"} • </span>
                  <span className='date'>{entity.created_at || "unknown"}</span>
                </span>
                <div>
                  {entity.plaintext 
                      ? entity.plaintext.trim().replace(/\s+/g, ' ').substring(0, 100) 
                      : 'No content available...'}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default SourceEntities;
